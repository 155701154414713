import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import Banner from '../components/Banner'
import ProfileContainer from '../components/ProfileContainer'
import Bio from '../components/Bio'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import Feed from '../components/Feed'
import About from '../components/About'
import Content from '../components/Content'
// import Projects from '../components/Projects'

const Home = () => {
  const [activeItem, setActiveItem] = useState('Feed')
  const navigate = useNavigate()

  const menuItems = [
    { name: 'Feed', href: '#feed' },
    { name: 'About', href: '#about' },
    { name: 'Content', href: '#content' },
    { name: 'Projects', href: '#projects' },
  ]
  
  useEffect(() => {
    if (activeItem === 'Projects') {
      navigate('/projects')
    }
  }, [activeItem, navigate])

  return (
    <div className='bg-gray0 min-h-screen'>
      <Header/>
      <Banner/>
      <ProfileContainer>
        <Bio />
        <Menu 
          activeItem={activeItem} 
          setActiveItem={setActiveItem} 
          menuItems={menuItems}
        />
        {activeItem === 'Feed' ? <Feed />
          : activeItem === 'About' ? <About />
          : <Content />
        }
      </ProfileContainer>
      <Footer />
    </div>
  )
}

export default Home
