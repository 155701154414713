import bioPic from './Bio.jpg'
import { IoBriefcaseOutline, IoLocationOutline } from 'react-icons/io5'
import { MdOutlineCalendarMonth, MdVerified } from 'react-icons/md'
import { PiGavel } from 'react-icons/pi'
import {Button} from '../ui/moving-border.tsx'
import CV from './CV_TorieCR.pdf'

const Bio = () => {
  const openPDF = () => {
    window.open(CV, '_blank')
  }
  return (
    <div className='flex flex-col items-center mt-12'>
      <div className='flex justify-between items-end relative w-full'>
        <img src={bioPic} alt='Bio pic' className='rounded-full w-40 h-40 mt-[-20rem] object-cover border-4 border-white'/>
        <div className='relative' style={{ bottom: '15px'}}>
          <div>
            <Button 
              containerClassName='h-10 w-32'
              className="bg-gray1 text-white border-slate-800 p-0"
              onClick={openPDF}
            >
              Judge Me <PiGavel className='ml-2' />
            </Button>
          </div>
        </div>
      </div>
      <div className='text-white0 pt-2 pb-2 w-full text-left'>
        <div className='flex items-center space-x-2'>
          <h1 className='text-2xl font-bold'>Torie CR</h1>
          <MdVerified className='text-2xl' />
        </div>
        <p className='text-gray-500'>@notARealTwitter</p>
        <p>Full Stack Developer by day, amateur bug bounty hunter by night.</p>
      </div>
      <div className='flex flex-col space-y-4 w-full'>
        <div className='text-grey3 flex space-x-4 items-center'>
          <div className='flex items-center space-x-2'>
            <IoBriefcaseOutline />
            <span className='text-xs sm:text-sm md:text-base'>Available</span>
          </div>
          <div className='flex items-center space-x-2'>
            <IoLocationOutline />
            <span className='text-xs sm:text-sm md:text-base'>Melbourne, Victoria</span>
          </div>
          <div className='flex items-center space-x-2'>
            <MdOutlineCalendarMonth />
            <span className='text-xs sm:text-sm md:text-base'>Joined March 2024</span>
          </div>
        </div>
        <div className='flex space-x-4'>
          <div className='flex items-center space-x-2'>
            <span className='text-white0 font-bold'>1 </span>
            <span className='text-gray-500'>Following</span>
          </div>
          <div className='flex items-center space-x-2'>
            <span className='text-white0 font-bold'>3.14 M </span>
            <span className='text-gray-500'>Followers</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bio
