import Board from './LightsOut/Board'
import ScrollingText from './ScrollingText'
import PlayingCard from './PlayingCard'

const Content = () => {
  return (
    <div>
      <div className='py-6'>
        <p className="pb-6 text-white0">
          Here I'll post a collection of mostly uncategorizable things I have made that don't really count as full-on projects. I'll also post blog updates.
        </p>
        <h1 className='text-white0 text-2xl font-bold'>Components</h1>
        <div className='parentContainer flex items-center justify-center py-10'>
          <ScrollingText />
        </div>
        <div className='py-10'>
          <Board />
          <p className="text-grey3 text-center text-sm mt-2">
            Can you black out the board?
          </p>
        </div>
        <div className='flex justify-center items-center py-10'>
          <PlayingCard />
        </div>
        <h1 className='text-white0 text-2xl font-bold'>Misc.</h1>
        <div className='pt-6 text-left'>
          <p className="text-white0">
            Admittedly, <a className='neon-text-tag' href='https://spacehey.com/scififox' target='_blank'>this</a> is <em>really</em> where my coding career began (circa 2006 on the OG site, but feel free to add me as a friend here!) How cool is it that it's been brough back to life? <em>Headphone warning: music might play.</em>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Content