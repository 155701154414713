import React from 'react'

const AceOfSpades = () => {
  return (
    <div className='w-64 h-96 flex justify-center items-center rounded-lg shadow-xl bg-white border border-gray-200 relative'>
      <div className='absolute top-4 left-4 flex flex-col items-center'>
        <div className='text-black text-2xl merriweather-font'>A</div>
        <div className='text-black text-3xl -mt-1'>♠️</div>
      </div>
      <div className='absolute bottom-4 right-4 transform rotate-180 flex flex-col items-center'>
        <div className='text-black text-2xl merriweather-font'>A</div>
        <div className='text-black text-3xl -mt-1'>♠️</div>
      </div>
      <div className='absolute flex justify-center items-center w-full h-full'>
        <div className='text-black text-9xl'>♠️</div>
      </div>
    </div>
  )
}

export default AceOfSpades
