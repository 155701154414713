import React, { useState } from 'react'

const Menu = (props) => {
  const {
    activeItem,
    setActiveItem,
    menuItems
  } = props

  return (
    <nav className='bg-gray0 text-white'>
      <ul className='flex justify-center space-x-4 py-2'>
        {menuItems.map((item) => (
          <li
            key={item.name}
            className={`px-4 py-2 cursor-pointer font-bold ${
              activeItem === item.name ? 'border-b-2 border-neonDarkGreen neon-text' : ''
            } hover:bg-gray1`}
            onClick={() => setActiveItem(item.name)}
          >
            {item.name}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Menu
