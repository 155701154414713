import React, { useState, useEffect } from 'react'
import { PiEyeglassesLight } from 'react-icons/pi'

const Header = () => {
  const [isTop, setIsTop] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      // When the scroll is more than 0, set isTop to false
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      setIsTop(scrollTop === 0)
    }

    // Add scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <header className={`w-full bg-gray0/80 h-[3.75rem] ${isTop ? 'absolute' : 'fixed'} top-0 z-50 flex items-center px-4 backdrop-blur-sm`}>
      {/* Content of the header */}
      <h1 className='text-white text-lg'>
        <PiEyeglassesLight />
      </h1>
      {/* Add more content or navigation items here */}
    </header>
  )
}

export default Header
