import React from 'react'
import DvdBounceLogo from './DvdBounceLogo'

const Banner = () => {
  return (
    <div className='flex justify-center w-full pt-15 mt-[3.75rem]'>
      <div className='w-full max-w-2xl'>
        <div id='parent' style={{height: '250px', backgroundColor: '#16191d', position: 'relative'}}>
          <DvdBounceLogo />
        </div>
      </div>
    </div>
  )
}

export default Banner
