import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import Home from './pages/Home'
import Blog from './components/Blog'
import AllProjects from './pages/AllProjects'

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/blog' element={<Blog />} />
        <Route path='/projects' element={<AllProjects />} />
        <Route path='*'element={<Home />} /> 
      </Routes>
    </Router>
  )
}

export default App
