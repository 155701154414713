import React from 'react'

const ProfileContainer = ({ children }) => {
  return (
    <div className='flex justify-center w-full pt-15'>
      <div className='w-full max-w-2xl px-4 md:px-6 lg:px-8'>
        {children}
      </div>
    </div>
  )
}

export default ProfileContainer
