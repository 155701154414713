import './index.css'

const ScrollingText = () => {
  return (
    <div className='content'>
      <div className='content__container'>
        <p className='content__container__text'>
          Hello,
        </p>
      
        <ul className='content__container__list'>
          <li className='content__container__list__item'>I'm Torie !</li>
          <li className='content__container__list__item'>world !</li>
          <li className='content__container__list__item'>everybody !</li>
          <li className='content__container__list__item'>recruiter !</li>
          <li className='content__container__list__item'>SOS !</li>
          <li className='content__container__list__item'>I'm trapped !</li>
        </ul>
      </div>
    </div>
  )
}
export default ScrollingText