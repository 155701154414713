import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Post from './Post'

const Feed = () => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    axios.get('https://dolphin-app-2it4s.ondigitalocean.app/api/posts?populate=*').then((response) => {
      const sortedPosts = response.data.data.sort((a, b) => {
        if (a.attributes.pinned && !b.attributes.pinned) return -1 
        if (!a.attributes.pinned && b.attributes.pinned) return 1
        return 0
      })

      setPosts(sortedPosts)
    })
  }, [])

  return (
    <>
      {posts?.map((post) => (
        <div key={post.id} className='pt-12'>
          <Post post={post.attributes} />
        </div>
      ))}
    </>
  )
}

export default Feed
