import React, { useState, useEffect } from 'react'
import Cell from './Cell'
import './Board.css'

const Board = ({ nrows = 5, ncols = 5, chanceLightStartsOn = 0.25 }) => {
  const createBoard = () => {
    let initialBoard = []
    for (let y = 0; y < nrows; y++) {
      let row = []
      for (let x = 0; x < ncols; x++) {
        row.push(Math.random() < chanceLightStartsOn)
      }
      initialBoard.push(row)
    }
    return initialBoard
  }
  const [board, setBoard] = useState(createBoard)
  const [hasWon, setHasWon] = useState(false)

  useEffect(() => {
    checkWin()
  }, [board])

  const flipCellsAround = (coord) => {
    setBoard(oldBoard => {
      const [y, x] = coord.split('-').map(Number)
      const flipCell = (y, x, boardCopy) => {
        if (x >= 0 && x < ncols && y >= 0 && y < nrows) {
          boardCopy[y][x] = !boardCopy[y][x]
        }
      }

      const boardCopy = oldBoard.map(row => [...row])
      flipCell(y, x, boardCopy)
      flipCell(y, x - 1, boardCopy)
      flipCell(y, x + 1, boardCopy)
      flipCell(y - 1, x, boardCopy)
      flipCell(y + 1, x, boardCopy)
      
      return boardCopy
    })
  }

  const checkWin = () => {
    const won = board.every(row => row.every(cell => !cell))
    setHasWon(won)
  }

  const makeTable = () => {
    let tblBoard = []
    for (let y = 0; y < nrows; y++) {
      let row = []
      for (let x = 0; x < ncols; x++) {
        let coord = `${y}-${x}`
        row.push(
          <Cell
            key={coord}
            isLit={board[y][x]}
            flipCellsAroundMe={() => flipCellsAround(coord)}
          />
        )
      }
      tblBoard.push(<tr key={y}>{row}</tr>)
    }
    return (
      <table className='Board'>
        <tbody>{tblBoard}</tbody>
      </table>
    )
  }

  return (
    <div className='px-2 py-8'>
      {hasWon ? (
        <div className='winner'>
          <span className='neon-orange'>YOU</span>
          <span className='neon-blue'>WIN!</span>
        </div>
      ) : (
        <div>
          <div className='Board-title'>
            <div className='neon-orange'>Lights</div>
            <div className='neon-blue'>Out</div>
          </div>
          {makeTable()}
        </div>
      )}
    </div>
  )
}

export default Board