import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { FaExternalLinkAlt, FaGithubSquare, FaArrowLeft } from 'react-icons/fa'

const AllProjects = () => {
  const [projects, setProjects] = useState([])

  useEffect(() => {
    axios.get('https://dolphin-app-2it4s.ondigitalocean.app/api/projects?populate=*').then((response) => {
      const sortedProjects = response.data.data.sort((a, b) => {
        if (a.attributes.year > b.attributes.year) return -1
        if (a.attributes.year < b.attributes.year) return 1
  
        if (a.attributes.pinned && !b.attributes.pinned) return -1
        if (!a.attributes.pinned && b.attributes.pinned) return 1
  
        return 0
      })
  
      setProjects(sortedProjects)
    })
  }, [])
  

  return (
    <div className='mx-auto p-2 md:p-4 lg:p-16 max-w-screen-xl'>
      <Link to='/' className='inline-flex items-center text-sm md:text-base font-bold neon-text mb-4'>
        <FaArrowLeft className='mr-2' />Torie CR
      </Link>

      <h1 className='text-lg md:text-2xl text-white font-bold mb-4'>Notable Projects</h1>

      <div className='hidden md:block overflow-x-auto'>
        <table className='w-full text-xs md:text-sm lg:text-base'>
          <thead className='text-left text-white'>
            <tr>
              <th className='p-2 md:p-4'>Year</th>
              <th className='p-2 md:p-4'>Project</th>
              <th className='p-2 md:p-4'>Company</th>
              <th className='p-2 md:p-4'>Built with</th>
              <th className='p-2 md:p-4'>Link</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) => {
              const proj = project.attributes
              const skills = proj.skills.split(',')
              return (
                <tr key={index} className='border-b border-gray-700'>
                  <td className='text-gray-500 p-2 md:p-4'>{proj.year}</td>
                  <td className='text-white0 p-2 md:p-4'>{proj.title}</td>
                  <td className='text-gray-500 p-2 md:p-4'>{proj.made_at}</td>
                  <td className='neon-text-tag p-2 md:p-4 flex flex-wrap'>
                    {skills.map((tag, tagIndex) => (
                      <span key={tagIndex} className='mr-2 mb-2 bg-neonGreenTags rounded-full px-2 py-1 text-xs md:text-sm inline-flex'>
                        {tag}
                      </span>
                    ))}
                  </td>
                  <td className='p-2 md:p-4'>
                    <div className="flex items-center space-x-2">
                      {proj.github_url && (
                        <a href={proj.github_url} target='_blank' rel='noopener noreferrer'>
                          <FaGithubSquare className='text-gray-500'/>
                        </a>
                      )}
                      {proj.demo_url && (
                        <a href={proj.demo_url} target='_blank' rel='noopener noreferrer'>
                          <FaExternalLinkAlt className='text-gray-500'/>
                        </a>
                      )}
                    </div>
                  </td>
                </tr>
              )})}
          </tbody>
        </table>
      </div>

      {/* Cards */}
      <div className='md:hidden'>
        {projects.map((project, index) => {
           const proj = project.attributes
           const skills = proj.skills.split(',')
          return (
            <div key={index} className='bg-gray1 text-white p-4 rounded-lg mb-4'>
              <h2 className='text-xl font-bold'>{proj.title}</h2>
              <p className='mt-2 text-gray-500'>
                {proj.year} &bull; &nbsp;
                {proj.made_at}
              </p>
              <div className='flex flex-wrap mt-2'>
                {skills.map((tag, tagIndex) => (
                  <span key={tagIndex} className='mr-2 bg-neonGreenTags neon-text-tag rounded-full px-3 py-1 text-xs my-1'>
                    {tag}
                  </span>
                ))}
              </div>
              <div className='mt-2'>
                <div className='flex items-center space-x-4'>
                  {proj.github_url && (
                    <div className='pt-2'>
                      <a href={proj.github_url} target='_blank' rel='noopener noreferrer' className='inline-flex'>
                        <FaGithubSquare className='text-gray-500'/>
                      </a>
                    </div>
                  )}
                  {proj.demo_url && (
                   <div className='pt-2'>
                    <a href={proj.demo_url} target='_blank' rel='noopener noreferrer' className='inline-flex'>
                      <FaExternalLinkAlt className='text-gray-500'/>
                    </a>
                   </div>
                  )}
                </div>
              </div>
            </div>
          )})}
      </div>
    </div>
  )
}

export default AllProjects
