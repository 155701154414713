import React from 'react'

const CardBack = () => {
  const radius = 7
  const diameter = radius * 2
  const gap = 0.5
  const borderSize = 20

  return (
    <div className='w-64 h-96 rounded-lg shadow-xl bg-red-800 flex justify-center items-center relative overflow-hidden'>
      <svg viewBox='0 0 200 300' className='absolute w-full h-full'>
        <rect x="0" y="0" width="100%" height="100%" fill="none" stroke="white" strokeWidth={borderSize} />
        {Array.from({ length: Math.floor((300 - borderSize * 2) / (diameter + gap)) + 1 }, (_, y) =>
          Array.from({ length: Math.floor((200 - borderSize * 2) / (diameter + gap)) }, (_, x) =>
            <circle
              key={`${x}-${y}`}
              cx={(diameter + gap) * x + radius + gap + borderSize}
              cy={(diameter + gap) * y + radius + gap + borderSize}
              r={radius}
              fill='white'
            />
          )
        )}
        <circle cx='100' cy='150' r='40' fill='white' />
      </svg>
      <div className='absolute inset-0 bg-red-800 opacity-50'></div>
    </div>
  )
}

export default CardBack
