import React, { useState, useEffect } from 'react'
import CardBack from './CardBack'
import AceOfSpades from './AceOfSpades'
const PlayingCard = () => {
  const [isFlipped, setIsFlipped] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleClick = () => {
    if (isMobile) {
      setIsFlipped(!isFlipped)
    }
  }

  return (
    <div className={`flip-card ${isMobile ? '' : 'hover:flip-card-inner'} `} onClick={handleClick}>
      <div className={`flip-card-inner ${isFlipped ? 'flipped' : ''}`}>
        <div className='card-front'>
          <AceOfSpades />
        </div>
        <div className='card-back'>
          <CardBack />
        </div>
      </div>
    </div>
  )
}

export default PlayingCard
