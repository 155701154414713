import aboutPic from './About.jpg'

const About = () => {
  return (
    <div className='flex flex-col items-center justify-center'>
      <h1 className="text-white0 text-2xl font-bold my-4 text-left self-start">Hello!</h1>
      <p className="text-white0">
        I’m Torie, a Front-End Web Developer with a passion for creating immersive digital experiences. Based in Melbourne, Australia, I bridge the worlds of visual effects and web development to bring creative visions to life online. With over 2.5 years of experience in the visual effects studio industry, I’m on a journey to expand my horizons as a Full-Stack Developer.
      </p>
      <br/>
      <p className="text-white0">
        I have a solid background in both physics and computer science, which gives me a unique ability to tackle and solve complex problems. Beyond my professional life, I'm passionate about exploring video game development, ethical hacking, and AI development (and wine and climbing on the weekends).
      </p>
      <br/>
      <img src={aboutPic} alt="About" className='max-w-xs rounded-lg' />
      <p className="text-grey3 text-sm mt-2">
        Torie at Grace Hopper Celebration of Women in Computing 2019.
      </p>
      <br/>
      <p className="text-white0">
        At heart, I’m an artistic soul committed to enjoying life, working hard on meaningful projects, and creating content that not only serves a purpose but also tells a story. Let’s collaborate and turn innovative ideas into reality, crafting digital experiences that engage, inspire, and make a lasting impact.
      </p>

    </div>
  )
}

export default About
