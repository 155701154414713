import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import { GoDotFill } from 'react-icons/go'
import { MdOutlinePushPin } from 'react-icons/md'

const Post = ({ post }) => {
  const dateString = post.date_posted
  const date = new Date(dateString)
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  }).format(date)

  return (
    <div className='flex flex-col p-4 hover:bg-gray1 transition-colors duration-150 ease-in-out rounded-md'>
      {post.pinned && (
        <div className='font-bold text-grey3 flex self-start mb-1 ml-11 items-center'>
          <MdOutlinePushPin className='text-lg mr-2'/> <span>Pinned</span>
        </div> 
      )}
      <div className='flex'>
        <div className='flex-shrink-0 mr-4'>
          <img src={post.permalink} alt='User Avatar' className='w-12 h-12 rounded-full object-cover' />
        </div>
        <div className='flex flex-col custom-markdown'>
          <div className='flex items-center space-x-2'>
            <div className='font-bold text-white0'>{post.username}</div>
            <div className='text-grey3'><GoDotFill /></div>
            <div className='text-grey3 text-sm'>{formattedDate}</div>
          </div>
          <div className='mt-1'>
            <span className='font-bold bg-grey2 text-sm text-white0 px-4 py-1 rounded-2xl inline-flex items-center whitespace-nowrap'>{post.status}</span>
          </div>
          <div className='prose mt-2'>
            <Markdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{post.body}</Markdown>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Post
