import React from 'react'
import { FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className='bg-gray0 text-white text-center pt-10 pb-8 px-8'>
      <div className='flex justify-center space-x-4 mb-4'>
        <a href='https://www.linkedin.com/in/toriecr/' aria-label='LinkedIn' className='text-2xl p-2 rounded-lg hover:bg-gray-500 transition duration-150 ease-in-out'target='_blank' rel='noopener noreferrer'><FaLinkedin /></a>
        <a href='https://github.com/toriecr' aria-label='Github' className='text-2xl p-2 rounded-lg hover:bg-gray-500 transition duration-150 ease-in-out' target='_blank' rel='noopener noreferrer'><FaGithub /></a>
        <a href='https://www.instagram.com/torie.jpeg/' aria-label='Instagram' className='text-2xl p-2 rounded-lg hover:bg-gray-500 transition duration-150 ease-in-out' target='_blank' rel='noopener noreferrer'><FaInstagram /></a>
      </div>
      <p className='text-sm mb-2'>© {new Date().getFullYear()} Torie CR. All rights reserved.</p>
      <p className='text-sm'>Built with Node.js for the backend and Tailwind CSS, deployed with Vercel.</p>     
    </footer>
  )
}

export default Footer
