import React, { useEffect } from 'react'

const DvdBounceLogo = () => {
  useEffect(() => {
    let x = 0
    let y = 0
    let dirX = 1
    let dirY = 1
    const speed = 2
    const palette = ['#ff8800', '#e124ff', '#6a19ff', '#ff2188']
    let prevColorChoiceIndex = 0

    const dvd = document.getElementById('dvd')
    const container = document.getElementById('parent')
    if (!dvd) return

    const dvdWidth = dvd.clientWidth
    const dvdHeight = dvd.clientHeight
    dvd.style.backgroundColor = palette[0]

    // const container = dvd.parentElement
    const containerHeight = container.clientHeight
    const containerWidth = container.clientWidth

    function getNewRandomColor() {
      const currentPalette = [...palette]
      currentPalette.splice(prevColorChoiceIndex, 1)
      const colorChoiceIndex = Math.floor(Math.random() * currentPalette.length)
      prevColorChoiceIndex =
        colorChoiceIndex < prevColorChoiceIndex ? colorChoiceIndex : colorChoiceIndex + 1
      return currentPalette[colorChoiceIndex]
    }

    function animate() {
      if (y + dvdHeight >= containerHeight || y < 0) {
        dirY *= -1
        dvd.style.backgroundColor = getNewRandomColor()
      }
      if (x + dvdWidth >= containerWidth || x < 0) {
        dirX *= -1
        dvd.style.backgroundColor = getNewRandomColor()
      }
      x += dirX * speed
      y += dirY * speed
      dvd.style.left = `${x}px`
      dvd.style.top = `${y}px`

      window.requestAnimationFrame(animate)
    }

    window.requestAnimationFrame(animate)

    return () => {
      window.cancelAnimationFrame(animate)
    }
  }, [])

  return (
    <div id='black'>
      <div id='dvd'></div>
    </div>
  )
}

export default DvdBounceLogo
